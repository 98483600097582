<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                <div class="flex flex-wrap items-center content-between justify-between">
                    <span class="font-semibold text-base">Data Pelanggan</span>
                    <div class="flex">
                        <div class="text-xs relative">
                            <div class="absolute top-2.5 left-2.5">
                                <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>

                            </div>

                            <input v-model="keyword" type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword" ref="keyword" id="keyword" @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                        </div>
                        <div>
                            <button class="text-white px-4 py-2 bg-blue-500 rounded ml-4"
                                @click="$router.push('/customer/add')">Tambah</button>
                        </div>
                    </div>

                </div>
                <div class="mt-4 w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                <td class="py-4">Nama</td>
                                <td>Email</td>
                                <td>Nomor Telepon</td>
                                <td>Mulai Kontrak</td>
                                <td>Batas Kontrak</td>
                                <td>Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id"
                                class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                <td class="py-4">
                                    <div class="flex items-center">
                                        <div class="w-8 h-8 rounded-full mr-2">
                                            <img :src="item.foto ? default_url + item.foto : default_url + 'default.png'"
                                                alt="" class="w-8 h-8 rounded-full object-cover">
                                        </div>
                                        <div>
                                            <p>{{ item.name }}</p>
                                            <p class="font-semibold">{{ item.company_name }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>{{ item.email }}</span>
                                </td>
                                <td>
                                    <span>{{ item.phone }}</span>
                                </td>
                                <td>
                                    <span>{{ parseDate(item.start_date, 'YYYY-MM-DD') }}</span>
                                </td>
                                <td>
                                    <span>{{ parseDate(item.expired_date, 'YYYY-MM-DD') }}</span>
                                </td>
                                <td>
                                    <div class="relative">
                                        <!-- Dropdown Toggle Button -->
                                        <button @click="toggleDropdown(item.id)" type="button"
                                            class="inline-flex items-center px-2 py-2 text-sm font-semibold text-white bg-gray-600 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                            <i class="fa fa-cog px-1"></i> Aksi
                                        </button>

                                        <!-- Dropdown Menu -->
                                        <div v-if="dropdownVisible === item.id"
                                            class="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
                                            <ul>
                                                <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    @click="$router.push('customer/edit/' + item.id)">
                                                    <i class="far fa-edit mr-2"></i> Edit
                                                </li>
                                                <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    @click="$router.push('customer/detail/' + item.id)">
                                                    <i class="far fa-eye mr-2"></i> View
                                                </li>
                                                <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    @click="openDelete(item.id)">
                                                    <i class="fa fa-trash mr-2"></i> Delete
                                                </li>
                                                <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    @click="$router.push('/customer_device?customer_id=' + item.id)">
                                                    <i class="fas fa-plus-circle mr-2"></i> Add Device
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                    <div class="flex justify-end items-center mt-4">
                        <div class="flex items-center ">
                            <div class="font-medium">
                                <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                            </div>
                            <div class="flex">
                                <unicon name="angle-left" class="cursor-pointer"
                                    :fill="currentPage > 1 ? '#00000' : '#A1A1AA'" @click="previousPage()"></unicon>
                                <unicon name="angle-right" class="cursor-pointer"
                                    :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                            </div>
                        </div>

                    </div>
                </div>
                <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteData()" v-if="modal_dialog" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'

export default {
    data: () => ({
        data: [],
        totalRows: 100,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        delete_id: '',
        modal_edit: false,
        dropdownVisible: null,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {
        getData() {
            this.axios.get('v1/customer?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows
                    this.totalRows = ress.data.count
                    if (this.totalRows <= this.limit) {
                        this.endNumber = this.totalRows
                    } else if (this.currentPage > 1) {
                        let page = this.currentPage - 1
                        this.startNumber = page * this.limit + 1
                        this.endNumber = this.currentPage * this.limit
                        if (this.totalRows < this.endNumber) {
                            this.endNumber = this.totalRows
                        }

                    } else {
                        this.startNumber = 1
                        this.endNumber = this.limit
                    }
                    this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1) * this.limit + 1

                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },

        deleteData() {
            this.axios.delete('v1/customer/' + this.delete_id, this.config)
                .then((ress) => {
                    this.closeModal()
                    this.getData()
                    this.$snack.success({
                        text: ress.data.message,

                    })
                })
        },
        toggleDropdown(id) {
            if (this.dropdownVisible === id) {
                this.dropdownVisible = null; // Close the dropdown if it's already open
            } else {
                this.dropdownVisible = id; // Open the dropdown for the selected item
            }
        },
        closeModal() {

            this.modal_dialog = !this.modal_dialog
        },
        eventChange(event) {
            this.default_url = ''
            const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.data.foto = fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
        },

    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
        }),

    },
    created() {
        this.getData()
    },
    components: {
        ValidationProvider, ValidationObserver, deleteComponent
    }
}
</script>